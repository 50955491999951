import { useState } from "react";
import JSZip from "jszip";
import { GroupedResult, QuestionSettings, SheetRow } from "../types";
import { LoadingIcon } from "../../shared/LoadingIcon";

import styles from "./Evaluatees.module.scss";
import { downloadBlob, exportToJson, exportToPdf, getFilename } from "./helpers";

interface EvaluateesProps {
  evaluatees: GroupedResult;
  questions: QuestionSettings;
}

export function Evaluatees({ evaluatees, questions }: EvaluateesProps): JSX.Element {
  const [exportingIds, setExportingIds] = useState<string[]>([]);

  const entries = Object.entries(evaluatees);

  const handleExportAllClick = async () => {
    const zip = new JSZip();
    let evaluationName: string | null = null;

    setExportingIds(Object.keys(evaluatees));
    const exportResult = await Promise.allSettled(
      entries.map(async ([name, evaluations]) => {
        const result = exportToJson(name, evaluations, questions);
        const data = await exportToPdf(result);
        const filename = getFilename(result.evaluationName, result.evaluee);

        if (!evaluationName) {
          evaluationName = result.evaluationName;
        }

        zip.file(filename, data, { binary: true });
      })
    );

    let shouldGenerateZip = false;

    const exportErrors = exportResult
      .filter((x) => x.status === "rejected")
      .map((x) => (x as PromiseRejectedResult).reason.message);

    if (exportErrors.length === entries.length) {
      window.alert("Impossible de générer les rapports");
    } else if (exportErrors.length > 0) {
      shouldGenerateZip = window.confirm(
        `Plusieurs rapports n'ont pas pu être exportés. \n Souhaitez-vous exporter les autres rapports tout de même? \n\n Erreurs : \n ${exportErrors.join(
          "\n"
        )}`
      );
    } else if (exportErrors.length === 0) {
      shouldGenerateZip = true;
    }

    if (shouldGenerateZip) {
      zip.generateAsync({ type: "blob" }).then((content: Blob) => {
        const filename = `${evaluationName}-All.zip`;
        downloadBlob(filename, content);
        setExportingIds([]);
      });
    } else {
      setExportingIds([]);
    }
  };

  const handleExportClick = async (name: string, evaluations: SheetRow[]) => {
    setExportingIds((ids) => [...ids, name]);
    const result = exportToJson(name, evaluations, questions);
    try {
      const blob = await exportToPdf(result);
      const filename = getFilename(result.evaluationName, result.evaluee);
      downloadBlob(filename, blob);
      setExportingIds((ids) => ids.filter((x) => x !== name));
    } catch (e) {
      console.error("OH BOY", e);
      alert("Error occured, call reinforcements");
      setExportingIds((ids) => ids.filter((x) => x !== name));
    }
  };

  return (
    <section className={styles.evaluatees}>
      <h2>Personnes évaluées</h2>
      {entries.length > 0 && (
        <button
          onClick={() => handleExportAllClick()}
          disabled={exportingIds.length === entries.length}>
          Exporter tous en PDF
          {exportingIds.length === entries.length && <LoadingIcon />}
        </button>
      )}
      <ul>
        {entries.map(([name, evaluations]) => (
          <li key={name}>
            <h3>
              {name} ({evaluations.length})
            </h3>
            <p>
              Par:{" "}
              {evaluations.map((x) => x[questions.parameters.evaluatorQuestionName]).join(", ")}
            </p>
            <button
              onClick={() => handleExportClick(name, evaluations)}
              disabled={exportingIds.includes(name)}>
              Exporter en PDF
              {exportingIds.includes(name) && <LoadingIcon />}
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
}
