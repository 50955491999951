import React from "react";
import styles from "./App.module.scss";

import { PeerEvaluations } from "./PeerEvaluations";

function App() {
  return (
    <div className={styles.app}>
      <h1>Eval 360 no-scope Akinox</h1>
      <PeerEvaluations />
    </div>
  );
}

export default App;
