import { Question, QuestionType } from "../types";
import { QuestionInput } from "./QuestionInput";

import styles from "./Questions.module.scss";

interface QuestionsProps {
  questions: Question[];
  onQuestionsChanged: (newQuestions: Question[]) => void;
}

export function Questions({ questions, onQuestionsChanged }: QuestionsProps): JSX.Element {
  const onQuestionTypeChanged = (name: string, newType: QuestionType) => {
    const newQuestions = questions.map((x) => {
      return {
        ...x,
        type: x.name === name ? newType : x.type,
      };
    });
    onQuestionsChanged(newQuestions);
  };

  return (
    <section className={styles.questions}>
      <h2>Questions trouvées</h2>
      <form>
        <table>
          <tbody>
            {questions.map((x) => (
              <QuestionInput
                question={x}
                onChange={(e) => onQuestionTypeChanged(x.name, e.target.value as QuestionType)}
                key={x.name}
              />
            ))}
          </tbody>
        </table>
      </form>
    </section>
  );
}
