import React from "react";
import { Question } from "../../types";
import styles from "./QuestionInput.module.scss";

interface QuestionInputProps {
  question: Question;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function QuestionInput({ question, onChange }: QuestionInputProps): JSX.Element {
  return (
    <tr className={styles["question-input"]}>
      <th>{question.name}</th>
      <td>
        <select onChange={onChange} value={question.type}>
          <option value="string">Texte</option>
          <option value="likert">Likert</option>
          <option value="nps">NPS</option>
          <option value="multi">Multiple</option>
          <option value="ignore">Ignorée</option>
        </select>
      </td>
    </tr>
  );
}
