import { QuestionType } from "./types";

const multi1 = ["Très faible", "Faible", "Moyen", "Élevé", "Très élevé", "Ne peut l'évaluer"];

const multi2 = [
  "Jamais",
  "Rarement",
  "Régulierement",
  "La majorité du temps",
  "Toujours",
  "Ne peut l'évaluer",
];

const ignoredKeywords = ["pas partagé"];

export function detectQuestionType(question: string, value: unknown): QuestionType {
  if (typeof value === "number") {
    if (value > 5) {
      return "nps";
    }

    return "likert";
  }

  if (typeof value === "string" && (multi1.includes(value) || multi2.includes(value))) {
    return "multi";
  }

  const loweredQuestion = question.toLowerCase();
  if (ignoredKeywords.some((keyword) => loweredQuestion.indexOf(keyword) >= 0)) {
    return "ignore";
  }

  return "string";
}
