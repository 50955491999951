import { Question, QuestionSettings, SheetRow } from "../types";
import {
  ExportEvaluation,
  ExportEvaluationAverage,
  ExportEvaluationMulti,
  ExportEvaluationString,
} from "./types";

export interface JsonExportResult {
  evaluationName: string;
  evaluee: string;
  evaluations: ExportEvaluation[];
}

export function exportToJson(
  name: string,
  evaluations: SheetRow[],
  questions: QuestionSettings
): JsonExportResult {
  return {
    evaluationName: questions.parameters.evaluationName,
    evaluee: name,
    evaluations: questions.questions
      .filter(
        (x) =>
          x.name !== questions.parameters.evaluateeQuestionName &&
          x.name !== questions.parameters.evaluatorQuestionName &&
          x.type !== "ignore"
      )
      .map((x) => transformQuestion(x, evaluations, questions.parameters.evaluatorQuestionName)),
  };
}

export function transformQuestion(
  question: Question,
  evaluations: SheetRow[],
  evaluatorQuestionName: string
): ExportEvaluation {
  switch (question.type) {
    case "string":
      return transformStringQuestion(question, evaluations, evaluatorQuestionName);

    case "likert":
      return transformAverageQuestion(question, evaluations, evaluatorQuestionName, 5);

    case "nps":
      return transformAverageQuestion(question, evaluations, evaluatorQuestionName, 10);

    case "multi":
      return transformMultiQuestion(question, evaluations, evaluatorQuestionName);

    case "ignore":
      throw new Error("Should not export ignored questions");

    default:
      throw new Error(`Question type ${question.type} not supported`);
  }
}

function transformStringQuestion(
  question: Question,
  evaluations: SheetRow[],
  evaluatorQuestionName: string
): ExportEvaluationString {
  return {
    type: "text",
    label: question.name,
    value: evaluations.map((x) => {
      return {
        label: x[question.name] as string,
        evaluator: x[evaluatorQuestionName] as string,
      };
    }),
  };
}

function transformAverageQuestion(
  question: Question,
  evaluations: SheetRow[],
  evaluatorQuestionName: string,
  scale: number
): ExportEvaluationAverage {
  return {
    type: "average",
    label: question.name,
    value:
      evaluations.map((x) => x[question.name] as number).reduce((p, c) => p + c, 0) /
      evaluations.length,
    scale,
    details: evaluations.map((x) => {
      return {
        value: x[question.name] as number,
        evaluator: x[evaluatorQuestionName] as string,
      };
    }),
  };
}

function transformMultiQuestion(
  question: Question,
  evaluations: SheetRow[],
  evaluatorQuestionName: string
): ExportEvaluationMulti {
  const values: Record<string, number> = {};
  evaluations.forEach((x) => {
    const questionName = x[question.name] as string;
    if (!values[questionName]) {
      values[questionName] = 0;
    }
    values[questionName]++;
  });
  return {
    type: "multi",
    label: question.name,
    value: Object.entries(values).map(([name, count]) => ({ label: name, count })),
    details: evaluations.map((x) => {
      return {
        value: x[question.name] as string,
        evaluator: x[evaluatorQuestionName] as string,
      };
    }),
  };
}

export async function exportToPdf(jsonResult: JsonExportResult, retry: number = 0): Promise<Blob> {
  const body = {
    externalId: "360individual",
    language: "fr",
    outputFormat: "pdf",
    data: jsonResult,
  };

  const response = await fetch("/export/Export", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: "Basic " + btoa("atlasuser:Xtkl45!!"),
    },
  });

  if (response.status !== 200) {
    if (retry < 3) {
      return exportToPdf(jsonResult, ++retry);
    } else {
      throw new Error(`Unable to export report for ${jsonResult.evaluee}`);
    }
  } else {
    return response.blob();
  }
}

export function getFilename(evaluationName: string, evaluee: string): string {
  return evaluationName + " - " + evaluee + ".pdf";
}

export async function downloadBlob(filename: string, blob: Blob): Promise<void> {
  const blobData = await blob;
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blobData);
  a.download = filename;
  a.click();
  a.remove();
}
